import React, { useEffect, useState } from "react";
import StakeCountWrapper from "./StakeCount.style"; // Import the CSS file
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Tooltip from "../tooltip";

const StakeCount = ({
    title,
    max,
    min,
    active,
    tooltip,
    onChangeHandler,
    type,
    checksuccess
}) => {
    const [inputValue, setInputValue] = useState(0);
    const [inputType, setInputType] = useState(type);
    const [checkSuccess, setCheckSuccess] = useState(false);
    useEffect(() => {
        setCheckSuccess(true);
    }, [checksuccess]);

    useEffect(() => {
        // Check if checksuccess is true, then set input value to 1
        if (checkSuccess) {
            setInputValue(0);
        }
    }, [checkSuccess]);
    useEffect(() => {
        setInputType(type);
    }, [type]);

    const [errorMessage, setErrorMessage] = useState('');

    const handleKeyDown = (e) => {
      setCheckSuccess(false);
      if (e.keyCode === 8 || e.key === "BackSpace") {
        setInputValue("");
      }
      // if (e.key === ".") {
      //     setErrorMessage('Dot or space is not allowed');
      //     setInputValue(1);
      // }
    };;
    const handleInputChange = (e) => {
      setCheckSuccess(false);
      const value = e.target.value;
      // Just update the state with the current input without validation
      setInputValue(value);
    };

    const handleInputBlur = () => {
      let validatedValue = parseInt(inputValue, 10);
      if (isNaN(validatedValue)) {
        validatedValue = min; // Use min if the input is not a number
      } else if (validatedValue.toString().includes(".") || validatedValue.toString().includes(" ")) {
        validatedValue = min;
        setErrorMessage("Dot or space is not allowed");
      } else if (validatedValue < min) {
        validatedValue = min;
      } else if (validatedValue > max) {
        validatedValue = max;
      } else {
        setErrorMessage(""); // Clear any error message if the final value is valid
      }
      setInputValue(validatedValue.toString()); // Ensure the state is updated with a string
    };;

    const maxButtonClicked = (max) => {
      setCheckSuccess(false);
      setInputValue(max);
    };

    useEffect(() => {
      onChangeHandler && onChangeHandler(type, inputValue);
    }, [inputValue, type]);

    return (
      <StakeCountWrapper>
        <div className="miner-container">
          <div className="tooltip_header_text">
            <h3>{title}</h3>
            <Tooltip text={tooltip}>
              <AiOutlineQuestionCircle />
            </Tooltip>
          </div>
          <input
            type="number"
            className="count_input"
            min={min}
            max={max}
            step="1"
            value={inputValue}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {active == 1 && (
            <button
              className="max_button"
              onClick={() => maxButtonClicked(max)}
            >
              MAX
            </button>
          )}
        </div>
        {errorMessage && (
          <div className="error_message">
            <p>{errorMessage}</p>
          </div>
        )}
      </StakeCountWrapper>
    );
};

export default StakeCount;
