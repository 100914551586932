import { ethers } from "ethers";

export const TreasuryAddress = "0xA2d21205Aa7273BadDFC8E9551e05E23bB49ce46";
export const HeliosAddress = "0x2614f29C39dE46468A921Fd0b41fdd99A01f2EDf";
export const HeliosPulseChainAddress = "0xaAdb63c2CDb9B07761aDC41b52436AeE8296CEbB";
export const BuyandburnAddress = "0x9Bff9F810D19cDb4BF7701C9d5aD101E91CdA08d";
export const TitanxAddress = "0xF19308F923582A6f7c465e5CE7a9Dc1BEC6665B1";
export const WETHTitanPoolAddress = "0xc45A81BC23A64eA556ab4CdF08A86B61cdcEEA8b";
export const WETHUsdtPoolAddress = "0xC5aF84701f98Fa483eCe78aF83F11b6C38ACA71D";
export const JanusContractAddress = "0xd5A96B6ACa28302CF1B0F860673851705D25667e";
export const JanusWPLSPoolAddress = "0x158b3b34ada976ee99e03fa368bdb277171e02d4";
export const pulseXFactoryAddress = "0x29eA7545DEf87022BAdc76323F373EA1e707C523";
export const InvestmentPoolAddressPls = "0x0E0730CeF84FB49fF8D8D514f0FFe1124F999F1b";
export const BunsAddress = "0xF7DE83B09fe727977Ed128564C84cbadBB083d35";
export const BunsPlsPoolAddress = "0x42dd10d8c296a54f923922e6db926cb392287a67";
export const AliceAddress = "0x896Bed39C73d24D6d11Fc18c854f5b64ca16f638";
export const AlicePlsPoolAddress = "0x5718449365297a4502350d04fe53fca848eccd02";
export const wpls = "0xa1077a294dde1b09bb078844df40758a5d0f9a27";
export const Hyper = "0xE2cfD7a01ec63875cd9Da6C7c1B7025166c2fA2F";
export const HyperETHPoolAddress = "0x43C6F294808835ffa39BC8A02B6542B19A872d7f";
export const Dragonx = "0x96a5399D07896f757Bd4c6eF56461F58DB951862";
export const DragonxETHPoolAddress =
  "0x66BEA722346efC72A5A2FC3D35abD7915547fEA0";
export const BabyDragonX = "0x9f278Dc799BbC61ecB8e5Fb8035cbfA29803623B";
export const BabyDragonXPoolAddress =
  "0x142f44D41B08d4C47C48Fe15098F066e146fEaF9";
export const Blaze = "0xfcd7cceE4071aA4ecFAC1683b7CC0aFeCAF42A36";
export const BlazeETHPoolAddress = "0x388331fc6B4ACD75478149F09FA41c653A37432D";
export const Inferno = "0x00F116ac0c304C570daAA68FA6c30a86A04B5C5F";
export const InfernoETHPoolAddress =
  "0xBdbc82263b92559296AEA3C0268BCfa69Ba69A20";
export const JakeX = "0xD60ABFB751dB36514a592963fD71DD50c6CF9Ba9";
export const JakeXETHPoolAddress = "0x608d5085589872AD058b4C657e358Cb4f6e3123B";

export const HatterAddress = "0xda49008Ef80629298416AfD3Cdf45c9B8Fd42d23";
export const HatterPlsPoolAddress =
  "0x82c43d77ebb0b2ea65f341b431d2f375c70a6c2c";

export const CheshireAddress = "0x2Bc3c187089ab3CD5eE5b63A1F792412Ec626700";
export const CheshirePlsPoolAddress =
  "0xba527f18b7d26090fd0771e7322f319dd3061486";

export const QueenAdress = "0x8CD965f641BD7Dc1D77943AC292ed543A8980c64";
export const QueenPlsPoolAddress = "0x8c112208c40ad808843f548a91cac21709056981";

export const BunsTreasuryAddress = "0x543d0A3eE8fF10f8068AaAaB53aDE156bF48F9eC";
export const JanusLpManagerAddress =
  "0x55C3C03e67A9ACA58912aFeB038c3EE8a84b9F8b";

export const JanusBuynBurnAddress =
  "0xCE34C0a9c895A9F15d2504F04b15241D31D1d863";

export const calculatePrice = (amount, price) => {
  return (parseFloat(amount, 10) * parseFloat(price, 10)).toFixed(2);
};
export const stringToFloat = (data) => {
  return parseFloat(data).toFixed(2);
};
export const calculateTotalPrice = (titanX, eth) => {
  return (parseFloat(titanX) + parseFloat(eth)).toFixed(2);
};
export const etherToFixed = (data) => {
  try {
    return parseFloat(ethers.utils.formatEther(data));
  } catch (e) {
    return parseFloat(
      ethers.utils.formatEther(exponentialToDecimal(data.toString()))
    );
  }
};

export const etherToFixed8Decimals = (data) => {
  try {
    return parseFloat(formatDecimal(data, 8));
  } catch (e) {
    console.log({ data });

    return parseFloat(formatDecimal(exponentialToDecimal(data.toString()), 8));
  }
};

export const formatDecimal = (data, decimals) => {
  const factor = Math.pow(10, decimals);
  const formattedData = parseFloat(data) / factor;
  return formattedData.toFixed(decimals);
};
export const formatNumber = (number, decimalPlaces) => {
  const num = parseFloat(number.toFixed(decimalPlaces));
  return num.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};
export const differenceDay = (startDay) => {
  const startDate = new Date(startDay);
  const currentDate = new Date();
  const differenceInMilliseconds = currentDate - startDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return Math.floor(differenceInDays).toFixed(0);
};
export const threeComma = (str) => {
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const exponentialToDecimal = (exponentialString) => {
  const parts = exponentialString.split("e+").map((part) => parseFloat(part));
  if (parts.length === 2) {
    let [base, exponent] = parts;
    let decimalString = base.toString();
    let decimalPos = decimalString.indexOf(".");
    let correctionFactor =
      decimalPos === -1 ? 0 : decimalString.length - decimalPos - 1;
    let adjustedExponent = exponent - correctionFactor;

    if (adjustedExponent >= 0) {
      return decimalString.replace(".", "") + "0".repeat(adjustedExponent);
    } else {
      const integerPart = decimalString.slice(0, decimalPos);
      const fractionalPart = decimalString.slice(decimalPos + 1);
      return (
        integerPart +
        fractionalPart.padEnd(
          integerPart.length + Math.abs(adjustedExponent),
          "0"
        )
      );
    }
  } else {
    return exponentialString; // Not in exponential format
  }
};
