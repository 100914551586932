import StakeContent from "../components/stake";

const Stake = () => {

  return (
    <>
      <StakeContent />
    </>
  );
};

export default Stake;
