import TreasuryContent from "../components/treasury";
const Treasury = () => {

  return (
    <>
      <TreasuryContent />
    </>
  );
};

export default Treasury;