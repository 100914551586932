import styled from "styled-components";

const NavWrapper = styled.nav`
  &.bithu_header {
    max-width: 1600px;
    margin: auto;
    padding: 16px 12px; /* Reduced padding */
    height: auto;
    transition: all 0.3s;
  }

  .bithu_menu_sect {
    margin: 10px 0; /* Reduced vertical margins */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bithu_menu_left_sect {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 10px; /* Reduced gap between logo and menu */

    .logo {
      img {
        width: 180px; /* Reduced logo size */
        height: auto; /* Maintain aspect ratio */
      }
    }

    .bithu_menu_right_sect {
      display: flex;
      flex-direction: row;
      position: relative;
      flex-shrink: 1; /* Allow shrinking */
      max-width: 800px; /* Prevent excessive width */
      width: auto;
    }

    .bithu_menu_list {
      margin-left: 10px; /* Reduced margin */
      /* Removed min-width to allow flexibility */
      width: auto;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        gap: 8px; /* Reduced gap between navigation links */

        li {
          position: relative;
          cursor: pointer;

          a {
            font-family: "Cinzel", sans-serif;
            font-weight: bold;
            font-size: 13px; /* Slightly reduced font size */
            text-decoration: none;
            padding: 4px 6px; /* Reduced padding */
            color: #f4b940;
            transition: color 0.1s ease, border-color 0.1s ease;
            white-space: nowrap;
          }

          &:hover a {
            color: #ba3505;
            border-color: #ba3505;
          }

          &.active a {
            border: 2px solid #fff;
            color: #fff;
            border-radius: 20px;
          }

          /* Submenu Styles */
          &.submenu {
            .sub_menu_sect {
              background: transparent;
              border-top: 50px solid transparent;
              position: absolute;
              top: -50px;
              left: -20px;
              width: 190px;
              visibility: hidden;
              opacity: 0;
              z-index: -100;
              transition: all 0.5s;

              .sub_menu_list {
                padding: 10px 15px; /* Reduced padding */
                background: #171f25;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                li {
                  width: 100%;

                  a {
                    font-family: "Bakbak One";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px; /* Slightly reduced font size */
                    line-height: 35px; /* Reduced line height */
                    color: rgba(255, 255, 255, 0.8);
                    text-transform: capitalize;
                  }

                  &:hover a {
                    color: #00ffa3;
                  }
                }
              }
            }

            &:hover .sub_menu_sect {
              top: 7px;
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }

  .connect_button {
    display: flex;
    align-items: center;
    gap: 8px; /* Reduced gap between tooltip and connect button */

    .header_pill_dropdown {
      margin: 0 8px 0 8px; /* Reduced margins */
      border: 2pt solid #f35000;
      border-radius: 20px;
      min-width: 100px; /* Reduced min-width */
      max-width: 140px; /* Adjusted max-width */
      // padding: 4px 6px; /* Reduced padding */
      line-height: 1;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      background-color: #2b0200;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .pill_day {
        text-align: center;
        font-size: 0.65rem; /* Slightly reduced font size */
        font-weight: bold;
        margin: 4px 0; /* Reduced margins */
      }

      .pill_hour_time {
        text-align: center;
        font-size: 0.55rem; /* Slightly reduced font size */
        margin: 2px 0; /* Reduced margins */
      }
    }
  }

  .bithu_menu_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;

    button {
      color: #f4b940;
      font-family: "Cinzel", sans-serif;
      font-weight: bold;
      font-size: 16px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 4px 8px; /* Reduced padding */

      &:hover {
        color: #ba3505;
      }
    }

    .menu_btn {
      display: none;
      border: 1px solid #f4b940;
      border-radius: 5px;
      background: transparent;
      cursor: pointer;

      svg {
        font-size: 30px; /* Reduced icon size */
      }
    }
  }

  /* Styles for specific classes (possibly dynamically generated) */
  .iekbcc0.ju367va.ju367v1n {
    height: 40px;
    border-radius: 40px;
    background: #ba3505;
    min-width: 165px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover._12cbo8i3 {
      transform: scale(1) !important;
    }

    button {
      background: transparent;
      border-color: transparent;
      box-shadow: none;
      white-space: nowrap;
    }

    .ju367v9x {
      background: transparent;
      border-color: transparent;
    }
  }

  /* Responsive Styles */
  @media (max-width: 1175px) {
    .bithu_menu_right_sect {
      justify-content: center;
    }

    .bithu_menu_btns {
      justify-content: center;
      margin-top: -5px;

      .menu_btn {
        display: block;
      }

      .join_btn {
        display: none;
      }
    }
  }

  @media (max-width: 1214px) {
    .bithu_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (max-width: 1024px) {
    .bithu_menu_list {
      margin-right: 10px; /* Further reduced margin */
    }
  }

  @media (max-width: 667px) {
    .bithu_menu_btns {
      .connect_btn {
        display: none;
      }

      .menu_btn {
        svg {
          font-size: 25px; /* Further reduced icon size */
        }
      }
    }
  }

  @media (max-width: 540px) {
    .bithu_menu_left_sect {
      width: 150px; /* Further reduced width */

      .logo {
        img {
          width: 120px; /* Further reduced logo size */
        }
      }
    }

    .logo {
      display: none;
    }

    .bithu_menu_right_sect {
      width: 50%;
    }
  }
`;

export default NavWrapper;
