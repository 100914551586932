import FooterBottom from "../footerBottom/v1";

import FooterStyleWrapper from "./Footer.style";
const Footer = () => {
  return (
    <FooterStyleWrapper>
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
