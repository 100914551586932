import RewardPoolContent from "../components/section/rewardpool";
const RewardPool = () => {

  return (
    <>
      <RewardPoolContent />
    </>
  );
};

export default RewardPool;
