import styled from "styled-components";

const JanusStyleWrapper = styled.div` 
.custom_tooltip {
    padding-top: 5px;
}
.tooltip_parent{
    display: flex;
    align-items: center;
    gap: 5px;
}
    margin: auto;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px;
    .social_part {
        width: 100%;    
        margin-top: 20px;
        display: inline-block;
        justify-content: center;
        text-align : center;
        
        .button_functions {
            padding-bottom: 10px;
            button { 
                padding-top: 9px;
                padding-bottom: 9px;
                background-color: #BA3505;
                border: 1px solid #FE9C01;
                border-radius: 30px;
                font-weight: bold;
                color: white;
                cursor: pointer;
                padding-right: 9px;
                padding-left: 9px;
            }
        }
        
    }
    .calculator_container {
        width: 100%;
        display: inline-block;
        align-items: center;
        font-family: 'Cinzel', sans-serif;

    .v2_footer_logo {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin: 15px 0;
        img{
            width: 100px;
            height: 100px;
            text-align: center;
            z-index: 1!important;
        }
        .footer_heading{
            display: flex;
            flex-direction: column;
            h1{
            font-size: 24px;
        font-family: 'Cinzel', sans-serif;
        margin:0;
        text-transform: uppercase;
            }            
        }
    }
    
    .tab_container {
        width: 50%;
        position: relative;
        display: block;
        padding: 0 10px;
        font-family: 'Montserrat', sans-serif;
        border : 1pt solid #fff;
        border-radius: 20px;
        overflow: hidden;
        z-index: 4;
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 3px;
        //     left: 3px;
        //     width: calc(100% - 6px);
        //     height: calc(100% - 6px);
        //     border-radius: inherit;
        //     background-color: #06064C;
        //     pointer-events: none;
        //     z-index: -1;
        //   }
        /* Multiple Background Layers */
        background: 
            // linear-gradient(to right, #FF3800, #F4B940, #A0AEC5, #004AAD), /* Gradient Border */
            rgba(6, 6, 76, 0.7); /* Semi-Transparent Background */

        /* Control Background Clipping */
        background-origin: border-box, padding-box;
        background-clip: border-box, padding-box;

        .tab-list {
            text-align: center;
            width: 100%;
            height: 40px;
            display: flex;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            justify-content:center;
            font-size: 19px;
            margin: 0;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1);
        }
        .tab_content {
            width: 100%;
            margin-bottom:10px;
            // text-align: center;
            justify-content: center;
        }
        
    }
    
}
.tab_info{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    gap: 15px;
    p{
        color: #fff;
        opacity: 0.89;
        letter-spacing: 0.4px;
        font-style: italic;
        line-height: 1.3;
        font-size: 14px;
        font-weight: 700;
        margin: 0 auto;
    }  
    a{
        color: #fff;
        text-decoration: underline; 
        cursor: pointer;
    }
}

.tabs {
    width: fit-content;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: center;
    align-items: center;
    background: #80808013;
    position: relative;
    cursor: pointer;
    padding: 5px 20px;
    margin: 0 3px -2px 0;
    font-weight: bold;
}
.modal_container{
    display: flex;
    gap: 20px;
    // opacity: 0.9;
}
.minter_count_input{
    height: 100%;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 2.25rem;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    outline: none;
    border: none;
    text-align: right;
    unicode-bidi: bidi-override;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}
.minter_count_input[type=number]{
    -moz-appearance: textfield;
}
.max_btn{
    height: 30px;
    width: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(186, 53, 5);
    border: 1px solid rgb(254, 156, 1);
    border-radius: 18px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.minter_droplist{
    display: flex;
    justify-content: space-between;
    
    .droplist_select{
        min-width: 140px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        padding: 5px;
        font-family: 'Montserrat', sans-serif;
        height: 38px;
    }
    .droplist_wrap{
        display: flex;
        flex-direction: column;
        line-height: 1.2;
        align-items: flex-end;
        p{
            margin: 0;
            color: #ffffffcc;
        font-size: 24px;
        font-weight: 700;
        }
        h6{
            font-size: 12px;
            margin: 0;
            color: #ffffffcc;
            font-weight: 200;
            font-style: italic;
            opacity: 0.5;
            margin-bottom: 5px;
        }
        span{
            margin: 0;
            font-size: 12px;
        font-weight: 400;
        margin: 0px;
        }
    }
}
.minter_droplist_h1{
    font-family: Cinzel, sans-serif;
    margin: 10px auto;
    margin-top: 20px;
    font-size: 24px;
    color: rgb(244, 185, 64);
}
.minter_division{
    position: relative;
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    width: 100%;
    gap: 4px;
    margin: 0 auto;
    padding: 5px 0;
    padding-bottom: 15px;
    h4{
        margin: 0;
        color: #ffffffcc;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    }
    span{
        text-align: end;
        margin: 0;
        font-size: 12px;
    font-weight: 400;
    margin: 0px;
    }

}
.minter_share{
    display: flex;
    margin: 5px 0;
    gap: 10px;
    align-items: center;
    font-family: Cinzel, sans-serif;
    font-weight: 700;
    h3{
        margin: 0;
    }
    span{
        color: lightgreen;
    }
}

.tabs:hover {}

.tabs:not(:last-child) {}

.active-tabs {
    background-color: rgba(255, 255, 255, 0.3);
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 4px;
}

.content {
    // background-color: #4a1700;
    display: none;
    padding: 10px;
}

.active-content {
    position: relative;
    
    display: block;
    padding: 0;

}
}
}

    .miner_setting_group {
        padding: 10px 20px 10px 20px;
        display: flex;
        flex-direction: column;
        .miner_asset_container{
            .miner_asset_right{
                display: flex;
                flex-direction: column;
                -webkit-box-align: end;
                align-items: end;
                line-height: 1.2;
                p {
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0px;
                }
                span {
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0px;
                    margin-bottom: 8px;
                }
                    }
            }
        .miner_asset{
            font-family: 'Cinzel', sans-serif;
            margin: 0;
            font-size: 16px;
        }
        .miner_asset_main{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 10px;
            .miner_asset_container{
                display: flex;
                justify-content: space-between;
                .miner_asset_left{
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0;
                }
                .miner_asset_right{
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    line-height: 1.2;
                    p{
                        font-size: 16px;
                        font-weight: 700;
                        margin: 0;
                    }
                    span{
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;  
                    }
                }
            }
        }
        .miner_hr{
            background-image: linear-gradient(to right, #FF3800, #F4B940, #A0AEC5, #004AAD);
            margin: 20px 0px 20px 0;
            border-radius: 5px;
            height: 2.5px;
        }
        .miner_burn_container{
            display: flex;
            justify-content: space-between;
            .miner_asset_left{
                display: flex;
                flex-direction: column;
                p{
                white-space: nowrap !important;
                font-size: 24px;
                font-weight: 700;
                margin: 0;
                color: #F4B940;
                padding-bottom: 5px;
                }
                .max_button {
                    width: 50px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    background-color: #BA3505;
                    border: 1px solid #FE9C01;
                    border-radius: 18px;
                    font-weight: bold;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .miner_asset_right{
                display: flex;
                flex-direction: column;
                align-items: end;
                line-height: 1.2;
                .miner_input_container{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .miner_input{
                        width: 100%;
                        .count_input{
                            height: 100%;
                            width: 100%;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 2.25rem;
                            background-color: transparent;
                            color: #F4B940;
                            outline: none;
                            border: none;
                            text-align: right;
                            unicode-bidi: bidi-override;
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                              -webkit-appearance: none;
                              margin: 0;
                            }
                        }
                        .count_input[type=number]{
                            -moz-appearance: textfield;
                        }
                    }
                }
                p{
                    color: #F4B940;
                font-size: 24px;
                    font-weight: 700;
                    margin: 0;
                }
                span{
                    padding-top: 5px;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;  
                    color: #F4B940;
                }
            }
        }
        .miner_cost_container{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            p{
                font-weight: 700;
                font-size: 18px;
                opacity: 0.8;
                margin: 0;
            }
        }
        .miner_roi_container{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            p{
                font-weight: 700;
                font-size: 18px;
                color: #F4B940;
                margin: 0;
            }
        }
        .miner_protocol_container{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            .miner_protocol_left{
                display: flex;
                flex-direction: column;
                p{
                    font-style: italic;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                color: #fff;
                opacity: 0.8;
                }
            }
            .miner_protocol_left p .custom_tooltip {
                opacity: 1; /* Overriding opacity for the tooltip */
                }
            .miner_protocol_right{
                display: flex;
                flex-direction: column;
                align-items: end;
                line-height: 1.2;
                .miner_input_container{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
                p{
                    font-style: italic;
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0;
                color: #fff;
                opacity: 0.5;
                }   
                span{
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;  
                color: #fff;
                opacity: 0.5;


                }
            }
        }
        .miner_janus_container{
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            padding-bottom: 20px;
            .miner_janus_left{
                display: flex;
                flex-direction: column;
                p{
                font-size: 22px;
                font-weight: 700;
                margin: 0;
                color: #fff;
                opacity: 1;
                }
            }
            .miner_janus_right{
                display: flex;
                flex-direction: column;
                align-items: end;
                line-height: 1.2;
                .miner_input_container{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
                p{
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                color: #fff;
                opacity: 1;
                }
                span{
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;  
                color: #fff;
                opacity: 0.5;


                }
            }
        }
        .miner_container{
            display: flex;
            border: 2px solid rgb(254, 156, 1);
            border-radius: 0.375rem;
            padding: 0.5rem;
            flex-direction: column;
            .miner_protocol{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 5px 0;
                h3{
                    color: #E5E5E5;
                    opacity: 0.8;
                    font-weight: 700;
                    font-size: 18px;
                    margin: 0;
                }
                h4{
                    color: #E5E5E5;
                    opacity: 0.8;
                    font-weight: 700;
                    font-size: 16px;
                    margin: 0;
                }
                &:nth-child(1){
                    border-bottom: 1px solid #E5E5E5;
                }
            }
            .miner_heading{
                display: flex;
                h6{
                    margin:0;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgb(254, 156, 1);
                }
                .miner_heading_janus{
                    color: rgb(186, 53, 5);
                }
            }
            .miner_input{
                height: 36px;
                width: 100%;
                .count_input{
                    height: 100%;
                    width: 100%;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 2.25rem;
                    background-color: transparent;
                    color: #a0a0a0;
                    outline: none;
                    border: none;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                }
                .count_input[type=number]{
                    -moz-appearance: textfield;
                }
            }
            .miner_balance{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h5{
                    color: #707070;
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.25rem;
                }
                .miner_balance_container{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    h5{
                        color: #707070;
                        margin: 0;
                        font-size: 14px;
                        line-height: 1.25rem;
                    }
                    .max_button {
                        width: 60px;
                        padding-top: 9px;
                        padding-bottom: 9px;
                        background-color: #A0AEC5 ;
                    border: 1px solid #FF3800;
                        border-radius: 20px;
                        font-weight: bold;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
        .position_card_name_with_balance {
            .position_card_balance_miner {
                text-align: end;
            }
        }
        h3.summary_roi_title {
            color: #f4b940;
            font-family: 'Cinzel', sans-serif;
        }
        .miner_input_button{
            display: flex;
            width: 100%;
            padding: 10px 0 10px 0;

        }
        &:nth-child(2){
            border-bottom: 2px solid rgba(255,255,255,0.1);
        }
    }
    .total_miner_count {
        display: inline-block;
        width: 100%;
        text-align: center;
        justify-content: center;
        color: #2ff712;
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
        border-top: 2px solid rgba(255, 255, 255, 0.1);
        p.top {
            font-size: 23px;
            font-weight: bold;
            margin: 10px;
        }
        p.bottom {
            font-size: 27px;
            font-weight: bold;
            margin: 10px;
        }
    }
    .miner-container {
        position: relative;
        display: inline-flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        h3 {
            width: 70%;
            font-size: 19px;
        }
        .gradient-border {
            padding: 2px;  /* Size of the gradient border */
            background: linear-gradient(to right, #FF3800, #F4B940, #A0AEC5, #004AAD);
            border-radius: 20px;
            display: inline-block;  /* Or flex, depending on layout needs */
            width: 100%;
        }
        .max_button {
            width: 100%;
            padding-top: 9px;
            padding-bottom: 9px;
            background-color: #A0AEC5 ;
            border-radius: 18px;
            border: none;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
        }

        .count_input {
            width: 17%;
            margin-right: 1%;
            height: 15px;
            padding: 10px;
            border: 2px solid #fff;
            border-radius: 5px;
            outline: none;
            font-size: 16px;
            transition: border-color 0.3s ease;
        }

        .count_input:focus {
            border-color: #000;
        }

        .summary_roi {
            width: 100%;
        }
    

    }
    #miner_length {
        width: 25%; 
    }
    .share_details {
        display: flex;
        margin-left: 20px;
        .share_details_left {
            width: 70%;
            font-size: 17px;
            p {
                font-weight: bold;
                margin: 5px 0 5px 0;
            }
        }
        .share_details_right {
            width: 30%;
            font-size: 17px;
            text-align: right;
            p {
                font-weight: bold;
                margin: 5px 0 5px 0;
            }
        }
    }
    .total_payout_amount {
        display: flex;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 0;
        justify-content: space-between;
        .payouts_total_name {
            span {
                margin-right: 5px;
            }
        }
    }
    .summary_stake_content {
        margin-left: 20px;
        margin-top: 13px;
        .payouts_total_box {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #fff;
            margin-top: 10px;
            .payouts_total_name {
                font-style: italic;
                margin-bottom : 15px;

                span {
                    margin-right: 5px;
                }
            }
            .payouts_total_dollar {}
        }
        .summary_staker_payout {
            display: flex;
            
            .summary_staker_left {
                width: 70%;
                font-size: 19px;
                font-weight: bold;
                align-items: center;
            }
            .summary_staker_right {
                width: 30%;
                font-size: 22px;
                text-align: right;
                font-weight: bold;
                display: grid;
                grid-auto-rows: max-content;

                .dollar {
                    font-size: 13px;
                    margin: 0;
                }
                .ether {
                    font-size: 13px;
                    margin: 0;    
                }
                .titanx_amount_pool {
                    font-size: 13px;
                    margin: 0;
                }
            }
        }
    }
    .payouts_total_dollar_total{
        font-weight: bold;
    }
    .position_card_name_miner {
        text-align: end!important;
    }
    .position_card_name_miner {
        text-align: start!important;
    }
    .payouts_total_name_total {
        font-weight:bold;
    }
    @media(max-width: 550px){
        padding-top: 0;
        padding-bottom: 0;
        .calculator_container .v2_footer_logo{
            img{
                width: 80px;
                height: 80px;
            }
           .footer_heading h1{
                font-size: 20px;
            }
        }
        .calculator_container h1{
            font-size: 22px;
            margin-top: 0;
        }
        .tab-list {
            min-height: 40px !important;
        }
        .tab-list .tabs{
            font-size: 16px;
        }
       .miner-container h3{
        font-size: 16px;
       }
       .total_miner_count p.bottom,.total_miner_count p.top{
        font-size: 22px;
        margin: 5px;
       }
      .miner_setting_group h3.summary_roi_title{
        font-size: 22px;
      }
      .position_card_name_with_balance{
        padding: 0 !important;
      }
      .position_card_name_miner h5, .position_card_name_miner h5.span_right{
        font-size: 16px;
      }
      .position_card_name h5,.position_card_balance{
        font-size: 16px;
      }
      .share_details{
        margin-left:0;
      }
      .miner_setting_group{
        padding: 10px 0;
      }
     .calculator_container .tab_container{
        padding: 0 9px;
      }
    }
    @media(max-width: 360px){
        .calculator_container .v2_footer_logo{
            img{
                width: 70px;
                height: 70px;
            }
           .footer_heading h1{
                font-size: 17px;
            }
        }
    }
    @media(max-width: 650px){
        .miner_burn_container{
            .miner_asset_left
                p{
                font-size: 16px !important;
                white-space: nowrap !important;
                }
            .miner_asset_right{
                .miner_input_container{
                    .miner_input{
                        .count_input{
                            font-size: 16px !important;
                        }
                    }
                }
                p{
                font-size: 16px !important;
                }
            }
        }
        .miner_setting_group  .miner_cost_container p{
            font-size: 16px;
        }
        .miner_setting_group  .miner_janus_container .miner_janus_left p{
            font-size: 16px;
        }
        .miner_setting_group  .miner_janus_container .miner_janus_right .miner_input_container p{
            font-size: 16px;
        }
    }
    @media(max-width: 1000px){
        max-width: 600px ;
    .modal_container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .tab_container{
            width: calc(100% - 20px);
        }
    }
    }
    @media(max-width: 500px){
    .droplist_wrap{
        line-height: 1.4;
        p{
        font-size: 16px !important;
        }
    }
    .minter_droplist_h1{
        font-size: 18px !important;
    }
    .minter_division{
        // width: 100% !important;
        h4{
        font-size: 16px !important;
        }
    }
    .minter_count_input{
        font-size: 16px !important;
    }
    // .max_btn{
    //     top: -4px  !important;
    //     right: -13px !important;
    // }
    .tab_info{
        width: 90% !important;
        p{
            font-size: 12px !important;
        }
    }
}
@media(max-width: 350px){
    .minter_division{
        // width: 100% !important;
        h4{
            font-size: 14px !important;
        }
        .minter_h4{
            width: 70% !important;
        }
    }
    .minter_count_input{
        font-size: 14px !important;
    }
    .tab_info{
        width: 95% !important;
    }
}
`;

export default JanusStyleWrapper;
