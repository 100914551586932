
import CalculatorContent from "../components/calculator";

const Calculator = () => {
    return (
        <>
            <CalculatorContent />
        </>
    );
};

export default Calculator;
