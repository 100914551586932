import React, { useEffect, useState } from "react";
import TreasuryContentStyleWrapper from "./TreasuryContent.style";
import DashboardText from "../../common/dashboardtext/DashboardText";
import tooltipText from "../../assets/helius/tooltipText.json";
import heliosContract from "../../contract/helios.json";
import treasuryContract from "../../contract/treasury.json";
import titanxContract from "../../contract/titanx.json";
import wplsPool from "../../contract/wplsPool.json";
import pool from "../../contract/pool.json";
import BigNumber from "bignumber.js";

import {
  useAccount,
  useReadContract,
  useReadContracts,
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useSwitchChain,
  useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
  TreasuryAddress,
  HeliosAddress,
  etherToFixed,
  TitanxAddress,
  InvestmentPoolAddressPls,
  BunsAddress,
  AliceAddress,
  HatterAddress,
  CheshireAddress,
  QueenAdress,
  BunsTreasuryAddress,
  BunsPlsPoolAddress,
  AlicePlsPoolAddress,
  HatterPlsPoolAddress,
  CheshirePlsPoolAddress,
  QueenPlsPoolAddress,
  Hyper,
  Dragonx,
  BabyDragonX,
  Blaze,
  Inferno,
  JakeX,
  HyperETHPoolAddress,
  DragonxETHPoolAddress,
  BabyDragonXPoolAddress,
  BlazeETHPoolAddress,
  InfernoETHPoolAddress,
  JakeXETHPoolAddress,
} from "../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaxStakeTooltip from "../modal/MaxStakeTooltip";
import ClaimDistTooltip from "../modal/ClaimDistTooltip";
import { ethers } from "ethers";
import { pulsechain, mainnet } from "wagmi/chains";

const TreasuryContent = () => {
  const { address, isConnected } = useAccount();

  // Start Get Redux store variables
  const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
  const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
  const pls_to_usd = useSelector((state) => state.price.pls_to_usd);
  const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd);
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();
  const [liquidTitanX, setLiquidTitanX] = useState(0);
  const [titanxStaked, setTitanxStaked] = useState(0);
  const [claimableETH, setClaimableETH] = useState(0);
  const [investmentBalance, setInvestimentBalance] = useState(0);
  const [investmentPlsBalance, setInvestimentPlsBalance] = useState(0);
  const [bunsTreasuryPlsBalance, setBunsTreasuryPlsBalance] = useState(0);
  const [bunsTreasuryBunsBalance, setBunsTreasuryBunsBalance] = useState(0);
  const [bunsTreasuryAliceBalance, setBunsTreasuryAliceBalance] = useState(0);
  const [bunsTreasuryHatterBalance, setBunsTreasuryHatterBalance] = useState(0);
  const [bunsTreasuryCheshireBalance, setBunsTreasuryCheshireBalance] =
    useState(0);
  const [bunsTreasuryQueenBalance, setBunsTreasuryQueenBalance] = useState(0);
  const [titanXUserBalance, setTitanXUserBalance] = useState(0);
  const [hyperUserBalance, setHyperUserBalance] = useState(0);
  const [heliosUserBalance, setHeliosUserBalance] = useState(0);
  const [dragonXUserBalance, setDragonXUserBalance] = useState(0);
  const [babyDragonsXUserBalance, setBabyDragonsXUserBalance] = useState(0);
  const [blazeUserBalance, setBlazeUserBalance] = useState(0);
  const [infernoUserBalance, setInfernoUserBalance] = useState(0);
  const [jakeXUserBalance, setJakeXUserBalance] = useState(0);

  const [hyperUsdtPrice, setHyperUsdtPrice] = useState(0);
  const [dragonXUsdtPrice, setDragonXUsdtPrice] = useState(0);
  const [babyDragonXUsdtPrice, setBabyDragonXUsdtPrice] = useState(0);
  const [blazeUsdtPrice, setBlazeUsdtPrice] = useState(0);
  const [infernoUsdtPrice, setInfernoUsdtPrice] = useState(0);
  const [jakeXUsdtPrice, setJakeXUsdtPrice] = useState(0);

  const [activeHlxStakeContract, setActiveHlxStakeContract] =
    useState(TreasuryAddress);
  const [disableButton, setDisableButton] = useState(false);

  const [bunsUsdtPrice, setBunsUsdtPrice] = useState(0);
  const [aliceUsdtPrice, setAliceUsdtPrice] = useState(0);
  const [hatterUsdtPrice, setHatterUsdtPrice] = useState(0);
  const [cheshireUsdtPrice, setCheshireUsdtPrice] = useState(0);
  const [queenUsdtPrice, setQueenUsdtPrice] = useState(0);
  const [userEther08, setuserEther08] = useState(0);
  const [userEther28, setuserEther28] = useState(0);
  const [userEther90, setuserEther90] = useState(0);
  const [userEther369, setuserEther369] = useState(0);
  const [userEther888, setuserEther888] = useState(0);

  useEffect(() => {
    if (chainId && chainId !== 1) {
      switchChain({ chainId: 1 });
    }
  }, [chainId, switchChain]);

  const heliosObj = {
    address: HeliosAddress,
    abi: heliosContract.abi,
  };
  const treasuryObj = {
    address: TreasuryAddress,
    abi: treasuryContract.abi,
  };
  const titanxObj = {
    address: TitanxAddress,
    abi: titanxContract.abi,
  };

  const { data: bunsPoolData } = useReadContracts({
    contracts: [
      {
        address: BunsPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: BunsPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: alicePoolData } = useReadContracts({
    contracts: [
      {
        address: AlicePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: AlicePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: hatterPoolData } = useReadContracts({
    contracts: [
      {
        address: HatterPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: HatterPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: cheshirePoolData } = useReadContracts({
    contracts: [
      {
        address: CheshirePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: CheshirePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: queensPoolData } = useReadContracts({
    contracts: [
      {
        address: QueenPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: QueenPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  useEffect(() => {
    if (bunsPoolData && bunsPoolData[0].status === "success") {
      const token0 = bunsPoolData[0].result;
      if (token0 === BunsAddress) {
        const BunsPriceInPLS =
          parseFloat(bunsPoolData[1].result[1].toString()) /
          parseFloat(bunsPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setBunsUsdtPrice(
            (
              parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const BunsPriceInPLS =
          parseFloat(bunsPoolData[1].result[0].toString()) /
          parseFloat(bunsPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setBunsUsdtPrice(
            (
              parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [bunsPoolData, pls_to_usd]);

  useEffect(() => {
    if (alicePoolData && alicePoolData[0].status === "success") {
      const token0 = alicePoolData[0].result;
      if (token0 === AliceAddress) {
        const AlicePriceInPLS =
          parseFloat(alicePoolData[1].result[1].toString()) /
          parseFloat(alicePoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setAliceUsdtPrice(
            (
              parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const AlicePriceInPLS =
          parseFloat(alicePoolData[1].result[0].toString()) /
          parseFloat(alicePoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setAliceUsdtPrice(
            (
              parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [alicePoolData, pls_to_usd]);

  useEffect(() => {
    if (hatterPoolData && hatterPoolData[0].status === "success") {
      const token0 = hatterPoolData[0].result;
      if (token0 === HatterAddress) {
        const HatterPriceInPLS =
          parseFloat(hatterPoolData[1].result[1].toString()) /
          parseFloat(hatterPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setHatterUsdtPrice(
            (
              parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const HatterPriceInPLS =
          parseFloat(hatterPoolData[1].result[0].toString()) /
          parseFloat(hatterPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setHatterUsdtPrice(
            (
              parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [hatterPoolData, pls_to_usd]);

  useEffect(() => {
    if (cheshirePoolData && cheshirePoolData[0].status === "success") {
      const token0 = cheshirePoolData[0].result;
      if (token0 === CheshireAddress) {
        const CheshirePriceInPLS =
          parseFloat(cheshirePoolData[1].result[1].toString()) /
          parseFloat(cheshirePoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setCheshireUsdtPrice(
            (
              parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const CheshirePriceInPLS =
          parseFloat(cheshirePoolData[1].result[0].toString()) /
          parseFloat(cheshirePoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setCheshireUsdtPrice(
            (
              parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [cheshirePoolData, pls_to_usd]);

  useEffect(() => {
    if (queensPoolData && queensPoolData[0].status === "success") {
      const token0 = queensPoolData[0].result;
      if (token0 === QueenAdress) {
        const QueensPriceInPLS =
          parseFloat(queensPoolData[1].result[1].toString()) /
          parseFloat(queensPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setQueenUsdtPrice(
            (
              parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const QueensPriceInPLS =
          parseFloat(queensPoolData[1].result[0].toString()) /
          parseFloat(queensPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setQueenUsdtPrice(
            (
              parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [queensPoolData, pls_to_usd]);

  const { data: investaddress } = useReadContract({
    address: TreasuryAddress,
    abi: treasuryContract.abi,
    functionName: "getInvestmentPoolAddress",
    watch: true,
  });

  const { data: treasuryReads } = useReadContracts({
    contracts: [
      {
        address: BunsAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: AliceAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: HatterAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: CheshireAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: QueenAdress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: TitanxAddress,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },
      {
        address: HeliosAddress,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },
      {
        address: Hyper,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },
      {
        address: Dragonx,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },

      {
        address: BabyDragonX,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },
      {
        address: Blaze,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },

      {
        address: Inferno,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },

      {
        address: JakeX,
        abi: titanxContract.abi,
        functionName: "balanceOf",
        args: [investaddress],
        chainId: mainnet.id,
      },
    ],
    watch: true,
  });

  useEffect(() => {
    if (treasuryReads && treasuryReads[0].status === "success") {
      setBunsTreasuryBunsBalance(
        treasuryReads[0]
          ? treasuryReads[0]?.status === "success"
            ? treasuryReads[0]?.result?.toString()
            : 0
          : 0
      );
      setBunsTreasuryAliceBalance(
        treasuryReads[1]
          ? treasuryReads[1]?.status === "success"
            ? treasuryReads[1]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryHatterBalance(
        treasuryReads[2]
          ? treasuryReads[2]?.status === "success"
            ? treasuryReads[2]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryCheshireBalance(
        treasuryReads[3]
          ? treasuryReads[3]?.status === "success"
            ? treasuryReads[3]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryQueenBalance(
        treasuryReads[4]
          ? treasuryReads[4]?.status === "success"
            ? treasuryReads[4]?.result?.toString()
            : 0
          : 0
      );
      setTitanXUserBalance(
        treasuryReads[5]
          ? treasuryReads[5]?.status === "success"
            ? treasuryReads[5]?.result?.toString()
            : 0
          : 0
      );
      setHeliosUserBalance(
        treasuryReads[6]
          ? treasuryReads[6]?.status === "success"
            ? treasuryReads[6]?.result?.toString()
            : 0
          : 0
      );
      setHyperUserBalance(
        treasuryReads[7]
          ? treasuryReads[7]?.status === "success"
            ? treasuryReads[7]?.result?.toString()
            : 0
          : 0
      );
      setDragonXUserBalance(
        treasuryReads[8]
          ? treasuryReads[8]?.status === "success"
            ? treasuryReads[8]?.result?.toString()
            : 0
          : 0
      );
      setBabyDragonsXUserBalance(
        treasuryReads[9]
          ? treasuryReads[9]?.status === "success"
            ? treasuryReads[9]?.result?.toString()
            : 0
          : 0
      );
      setBlazeUserBalance(
        treasuryReads[10]
          ? treasuryReads[10]?.status === "success"
            ? treasuryReads[10]?.result?.toString()
            : 0
          : 0
      );
      setInfernoUserBalance(
        treasuryReads[11]
          ? treasuryReads[11]?.status === "success"
            ? treasuryReads[11]?.result?.toString()
            : 0
          : 0
      );
      setJakeXUserBalance(
        treasuryReads[12]
          ? treasuryReads[12]?.status === "success"
            ? treasuryReads[12]?.result?.toString()
            : 0
          : 0
      );
    }
  }, [treasuryReads]);

  const { data } = useReadContracts({
    contracts: [
      {
        ...heliosObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 22],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 69],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 420],
      },
      {
        ...heliosObj,
        functionName: "getUndistributedTitanX",
      },
      {
        ...heliosObj,
        functionName: "getUndistributedETH",
      },
      {
        ...titanxObj,
        functionName: "getCyclePayoutPool",
        args: [8],
      },
      {
        ...titanxObj,
        functionName: "getCyclePayoutPool",
        args: [28],
      },
      {
        ...titanxObj,
        functionName: "getCyclePayoutPool",
        args: [90],
      },
      {
        ...titanxObj,
        functionName: "getCyclePayoutPool",
        args: [369],
      },
      {
        ...titanxObj,
        functionName: "getCyclePayoutPool",
        args: [888],
      },
    ],
    watch: true,
  });

  const { data: treasuryData } = useReadContracts({
    contracts: [
      {
        ...treasuryObj,
        functionName: "getTitanBalance",
      },
      {
        ...treasuryObj,
        functionName: "getTotalTitanStaked",
      },
      {
        ...treasuryObj,
        functionName: "getTotalTitanUnstaked",
      },
      {
        ...titanxObj,
        functionName: "getUserETHClaimableTotal",
        args: [activeHlxStakeContract],
      },
      {
        ...treasuryObj,
        functionName: "activeHlxStakeContract",
      },
    ],
    watch: true,
  });

  const { data: userEst } = useReadContracts({
    contracts: [
      {
        ...titanxObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...titanxObj,
        functionName: "getUserCurrentActiveShares",
        args: [activeHlxStakeContract],
      },
    ],
    watch: true,
  });

  /**
   *@description: Max Stake button stakeTITANX()
   */
  const {
    data: maxStakeData,
    writeContract: maxStake,
    error: maxStakeError,
    isLoading: isMaxStakeLoading,
  } = useWriteContract();

  const maxStakeClicked = async () => {
    maxStake({
      address: TreasuryAddress,
      abi: treasuryContract.abi,
      functionName: "stakeTITANX",
      args: [],
    });
  };

  const { isSuccess: isMaxStakeConfirmed } = useWaitForTransactionReceipt({
    hash: maxStakeData,
  });

  useEffect(() => {
    if (maxStakeError) {
      toast.error(
        "Minimum wait time of 7 days or minimum 100B TITANX balance required",
        {
          autoClose: 5000,
        }
      );
    }
  }, [maxStakeError]);

  useEffect(() => {
    if (isMaxStakeConfirmed) {
      toast.success("Staked Successfully", {
        autoClose: 5000,
      });
    }
  }, [isMaxStakeConfirmed]);

  /**
   *@description: Claim Distribute -> claimReward()
   */
  const {
    data: claimDistributeData,
    writeContract: claimDistribute,
    error: claimDistributeError,
    isLoading: isClaimDistributeLoading,
  } = useWriteContract();

  const { isSuccess: isClaimDistributeConfirmed } =
    useWaitForTransactionReceipt({
      hash: claimDistributeData,
    });

  useEffect(() => {
    if (claimDistributeError) {
      toast.error("There is no ETH to claim", {
        autoClose: 5000,
      });
    }
  }, [claimDistributeError]);

  useEffect(() => {
    if (data && data[12].status === "success") {
      if (userEst && userEst[0].status === "success") {
        if (parseFloat(userEst[0]?.result || 0) !== 0) {
          const userEstRate =
            (userEst[1] ? userEst[1]?.result?.toString() || 0 : 0) /
            (userEst[0] ? userEst[0]?.result?.toString() || 0 : 0);

          setuserEther08(
            etherToFixed(data[12] ? data[12]?.result || 0 : 0) * userEstRate
          );
          setuserEther28(
            etherToFixed(data[13] ? data[13]?.result || 0 : 0) * userEstRate
          );
          setuserEther90(
            etherToFixed(data[14] ? data[14]?.result || 0 : 0) * userEstRate
          );
          setuserEther369(
            etherToFixed(data[15] ? data[15]?.result || 0 : 0) * userEstRate
          );
          setuserEther888(
            etherToFixed(data[16] ? data[16]?.result || 0 : 0) * userEstRate
          );
        }
      }
    }
  }, [data, userEst]);

  useEffect(() => {
    if (isClaimDistributeConfirmed) {
      toast.success("Claim Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isClaimDistributeConfirmed]);

  const claimDistributeClicked = async () => {
    claimDistribute({
      address: TreasuryAddress,
      abi: treasuryContract.abi,
      functionName: "claimReward",
      args: [],
    });
  };

  const { data: userBalance } = useBalance({
    address: investaddress ? investaddress : ethers.constants.AddressZero,
    chainId: mainnet.id,
  });

  const { data: userPlsBalance } = useBalance({
    address: InvestmentPoolAddressPls,
    chainId: pulsechain.id,
  });

  const { data: userBunsTreasuryPLSBalance } = useBalance({
    address: BunsTreasuryAddress,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (data && data[1].status === "success") {
      if (
        (data[11] ? data[11]?.result?.toString() || 0 : 0) === 0 &&
        (data[10] ? data[10]?.result?.toString() || 0 : 0) === 0
      ) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
    if (userBalance) {
      setInvestimentBalance(userBalance ? userBalance.value : 0);
    }
    if (userPlsBalance) {
      setInvestimentPlsBalance(userPlsBalance ? userPlsBalance.value : 0);
    }
    if (userBunsTreasuryPLSBalance) {
      setBunsTreasuryPlsBalance(
        userBunsTreasuryPLSBalance ? userBunsTreasuryPLSBalance.value : 0
      );
    }

    if (treasuryData && treasuryData[0].status === "success") {
      setLiquidTitanX(
        treasuryData[0] ? treasuryData[0]?.result?.toString() || 0 : 0
      );
      setTitanxStaked(
        treasuryData[1]
          ? treasuryData[2]
            ? (
                parseFloat(etherToFixed(treasuryData[1]?.result || 0)) -
                parseFloat(etherToFixed(treasuryData[2]?.result || 0))
              )?.toString()
            : 0
          : 0
      );
      setActiveHlxStakeContract(
        treasuryData[4] ? treasuryData[4]?.result?.toString() || 0 : 0
      );
    }
  }, [
    data,
    treasuryData,
    disableButton,
    userBalance,
    userBunsTreasuryPLSBalance,
    userPlsBalance,
  ]);

  useEffect(() => {
    if (treasuryData && treasuryData[3].status === "success") {
      setClaimableETH(
        treasuryData[3] ? treasuryData[3]?.result?.toString() || 0 : 0
      );
    }
  }, [treasuryData]);

  const sqrtPriceX96ToEthPrice = (
    sqrtPriceX96,
    token0,
    token1,
    targetToken
  ) => {
    const sqrtPrice = new BigNumber(sqrtPriceX96.toString());
    const priceInETH = sqrtPrice.pow(2).div(new BigNumber(2).pow(192));
    if (token0 === targetToken) {
      // Token0 is the token of interest, so priceInETH is correct
      return priceInETH.toPrecision();
    } else if (token1 === targetToken) {
      // Token1 is the token of interest, so invert the price
      return new BigNumber(1).div(priceInETH).toPrecision();
    }
    return 0;
  };

  const calculateUsdPrice = (priceInETH, ethToUsdPrice) => {
    return priceInETH * ethToUsdPrice;
  };

  const { data: slot0 } = useReadContracts({
    contracts: [
      {
        address: HyperETHPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: HyperETHPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: HyperETHPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
      {
        address: DragonxETHPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: DragonxETHPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: DragonxETHPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
      {
        address: BabyDragonXPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: BabyDragonXPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: BabyDragonXPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
      {
        address: BlazeETHPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: BlazeETHPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: BlazeETHPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
      {
        address: InfernoETHPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: InfernoETHPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: InfernoETHPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
      {
        address: JakeXETHPoolAddress,
        abi: pool.abi,
        functionName: "slot0",
        chainId: mainnet.id,
      },
      {
        address: JakeXETHPoolAddress,
        abi: pool.abi,
        functionName: "token0",
        chainId: mainnet.id,
      },
      {
        address: JakeXETHPoolAddress,
        abi: pool.abi,
        functionName: "token1",
        chainId: mainnet.id,
      },
    ],
  });

  useEffect(() => {
    if (slot0 && slot0[0].status === "success") {
      const ethToUsdPrice = ether_to_usd; // Fetch from Redux store

      // Calculate prices for each token
      const hyperPriceInETH = sqrtPriceX96ToEthPrice(
        slot0[0]?.result[0],
        slot0[1]?.result,
        slot0[2]?.result,
        Hyper
      );
      const dragonxPriceInETH = sqrtPriceX96ToEthPrice(
        slot0[3]?.result[0],
        slot0[4]?.result,
        slot0[5]?.result,
        Dragonx
      );

      const blazePriceInETH = sqrtPriceX96ToEthPrice(
        slot0[9]?.result[0],
        slot0[10]?.result,
        slot0[11]?.result,
        Blaze
      );
      const infernoPriceInETH = sqrtPriceX96ToEthPrice(
        slot0[12]?.result[0],
        slot0[13]?.result,
        slot0[14]?.result,
        Inferno
      );
      const jakeXPriceInETH = sqrtPriceX96ToEthPrice(
        slot0[15]?.result[0],
        slot0[16]?.result,
        slot0[17]?.result,
        JakeX
      );

      // Convert to USD
      setHyperUsdtPrice(calculateUsdPrice(hyperPriceInETH, ethToUsdPrice));
      setDragonXUsdtPrice(calculateUsdPrice(dragonxPriceInETH, ethToUsdPrice));
      setBlazeUsdtPrice(calculateUsdPrice(blazePriceInETH, ethToUsdPrice));
      setInfernoUsdtPrice(calculateUsdPrice(infernoPriceInETH, ethToUsdPrice));
      setJakeXUsdtPrice(calculateUsdPrice(jakeXPriceInETH, ethToUsdPrice));
    }
  }, [slot0, ether_to_usd]);

  useEffect(() => {
    if (slot0 && slot0[6].status === "success") {
      const babyDragonXPriceInDragonX = sqrtPriceX96ToEthPrice(
        slot0[6]?.result[0],
        slot0[7]?.result,
        slot0[8]?.result,
        BabyDragonX
      );

      setBabyDragonXUsdtPrice(
        calculateUsdPrice(babyDragonXPriceInDragonX, dragonXUsdtPrice)
      );
    }
  }, [slot0, dragonXUsdtPrice]);

  return (
    <TreasuryContentStyleWrapper>
      <div className="rows">
        <div className="cols">
          <div className="reward_pool_cards">
            <div className="cards_contents">
              <h1>Titanx Treasury </h1>

              <div className="card_content_rewards">
                <DashboardText
                  token="TITANX"
                  name="Liquid Titanx"
                  balance={parseFloat(
                    etherToFixed(liquidTitanX)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={parseFloat(
                    titanx_to_usd * parseFloat(etherToFixed(liquidTitanX))
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "liquidtitanx" in asset
                    )?.liquidtitanx
                  }
                />
                <DashboardText
                  token="TITANX"
                  name="Titanx Staked"
                  balance={parseFloat(titanxStaked).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={parseFloat(
                    titanx_to_usd * parseFloat(titanxStaked)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "titanxstaked" in asset
                    )?.titanxstaked
                  }
                />
                <DashboardText
                  token="ETH"
                  name="Future Est. Payouts"
                  balance={parseFloat(
                    userEther08 +
                      userEther28 +
                      userEther90 +
                      userEther369 +
                      userEther888
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5,
                  })}
                  usd={parseFloat(
                    ether_to_usd *
                      parseFloat(
                        userEther08 +
                          userEther28 +
                          userEther90 +
                          userEther369 +
                          userEther888
                      )
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "estimateETHPayout" in asset
                    )?.estimateETHPayout
                  }
                />
                <DashboardText
                  token="ETH"
                  name="Claimable Rewards"
                  balance={parseFloat(
                    etherToFixed(claimableETH)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                  usd={parseFloat(
                    ether_to_usd * parseFloat(etherToFixed(claimableETH))
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "claimablerewards" in asset
                    )?.claimablerewards
                  }
                />
              </div>
              <div className="reward_pool_btn_group">
                <MaxStakeTooltip>
                  <button
                    className="distribute_button"
                    disabled={!(isConnected && !isMaxStakeLoading)}
                    onClick={maxStakeClicked}
                  >
                    MAX STAKE
                  </button>
                </MaxStakeTooltip>
                <ClaimDistTooltip claimData={claimableETH}>
                  <button
                    className="distribute_button"
                    disabled={!(isConnected && !isClaimDistributeLoading)}
                    onClick={claimDistributeClicked}
                  >
                    CLAIM & DISTRIBUTE
                  </button>
                </ClaimDistTooltip>
              </div>
            </div>
            <div className="cards_contents">
              <h1>Wonderland Treasury </h1>
              <div className="card_content_rewards">
                <DashboardText
                  token="QUEEN"
                  name="Red"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryQueenBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryQueenBalance)) *
                    queenUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "queenBalance" in asset
                    )?.queenBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="CHESHIRE"
                  name="Cat"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryCheshireBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryCheshireBalance)) *
                    cheshireUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "cheshireBalance" in asset
                    )?.cheshireBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="HATTER"
                  name="Mad"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryHatterBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryHatterBalance)) *
                    hatterUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "hatterBalance" in asset
                    )?.hatterBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="ALICE"
                  name="Wonderland"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryAliceBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryAliceBalance)) *
                    aliceUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "aliceBalance" in asset
                    )?.aliceBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="BUNS"
                  name="White Rabbit"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryBunsBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryBunsBalance)) *
                    bunsUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "bunsBalance" in asset
                    )?.bunsBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="PLS"
                  name="Pulse"
                  balance={parseFloat(
                    etherToFixed(bunsTreasuryPlsBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(bunsTreasuryPlsBalance)) *
                    pls_to_usd
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "bunsPlsBalance" in asset
                    )?.bunsPlsBalance
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cols">
          <div className="reward_pool_cards">
            <div className="cards_contents">
              <h1>Investment Pool </h1>

              <div className="card_content_rewards">
                <DashboardText
                  token="ETH"
                  name="Ethereum"
                  balance={parseFloat(
                    etherToFixed(investmentBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                  usd={(
                    parseFloat(etherToFixed(investmentBalance)) * ether_to_usd
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "balance" in asset
                    )?.balance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="PLS"
                  name="Pulse"
                  balance={parseFloat(
                    etherToFixed(investmentPlsBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(investmentPlsBalance)) * pls_to_usd
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "plsBalance" in asset
                    )?.plsBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="TITANX"
                  name="TitanX"
                  balance={parseFloat(
                    etherToFixed(titanXUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(titanXUserBalance)) * titanx_to_usd
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "titanXBalance" in asset
                    )?.titanXBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="HYPER"
                  name="Hyper"
                  balance={parseFloat(
                    etherToFixed(hyperUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(hyperUserBalance)) * hyperUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "hyperBalance" in asset
                    )?.hyperBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="HLX"
                  name="Helios"
                  balance={parseFloat(
                    etherToFixed(heliosUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(heliosUserBalance)) * hlx_to_usd
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "hlxBalance" in asset
                    )?.hlxBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="DRAGONX"
                  name="DragonX"
                  balance={parseFloat(
                    etherToFixed(dragonXUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(dragonXUserBalance)) *
                    dragonXUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "dragonXBalance" in asset
                    )?.dragonXBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="BDX"
                  name="Baby DragonX"
                  balance={parseFloat(
                    etherToFixed(babyDragonsXUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(babyDragonsXUserBalance)) *
                    babyDragonXUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "babyDragonXBalance" in asset
                    )?.babyDragonXBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="BLAZE"
                  name="Blaze"
                  balance={parseFloat(
                    etherToFixed(blazeUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(blazeUserBalance)) * blazeUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "blazeBalance" in asset
                    )?.blazeBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="INF"
                  name="Inferno"
                  balance={parseFloat(
                    etherToFixed(infernoUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(infernoUserBalance)) *
                    infernoUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "infernoBalance" in asset
                    )?.infernoBalance
                  }
                />
              </div>
              <div className="card_content_rewards">
                <DashboardText
                  token="JAKEX"
                  name="JakeX"
                  balance={parseFloat(
                    etherToFixed(jakeXUserBalance)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={(
                    parseFloat(etherToFixed(jakeXUserBalance)) * jakeXUsdtPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.rewardpools[1].rightbox.find(
                      (asset) => "jakeXBalance" in asset
                    )?.jakeXBalance
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TreasuryContentStyleWrapper>
  );
};

export default TreasuryContent